
body {
  padding: 0;
}

#logo img {
  max-width: 100%;
  vertical-align: middle;
  border: 0px none;
  float: right;
}

#standard_header {
  padding : 0 20px 15px 20px;
  margin-bottom : 20px;
  border-bottom : 2px solid #ddd;
}

#infotext {
  padding : 0 20px 15px 20px;
}

/*
 * Thumbnails
 */

.thumbnail {
  border: none;
  margin-top: 30px;
  margin-bottom: 0px;
}

a.thumbnail{
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.thumbnails {
  text-align: center;
  margin-left: -30px;

 .col {
    width: 32%;
  }

  @media (max-width: 992px) {
    .col {
      width: 49%;
    }
  }

  li {
    float: none !important; /* to overwrite the default property on the bootstrap stylesheet */
    padding: 0;
    display: inline-block;
    *display: inline; /* ie7 support */
  }

  a:hover, a:focus {
    color: #000;
    text-decoration: underline;
  }

  a:active, a:hover {
    outline: 0px none;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  h4 {
    text-align: center;
    font-family: Helvetica,sans-serif;
    margin-top: 2px;
    margin-bottom: 30px;
    padding: 0px;
    font-size: 14px;
    color: #000;
  }
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
#sidebar {
}

@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #eee;
    border-right: 10px solid #ddd;
  }
}

@media (max-width: 768px) {
  #logo img {
    float: left;
  }
}

.container {
  font-family: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 1.4;
}

#body #downloads {
  font-size: 15px;

  p {
    text-align: left;
    margin: 5px 0;
  }

  h3 {
    font-size: 16px;
  }

  .panel-title {
    margin: 0;
  }

  .panel-body {
    height: 310px;
  }
}
