#menu {
  width: 210px;
  font-size: 14px;
  text-align: left;
  margin: 20px 0px 0px 80px;
  float: right;

  font-family: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 200;


  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    a, span.current {
      display: block;
      padding: 3px 5px 3px 10px;
      text-decoration: none;
    }

    a, a:visited {
      color: #777;
      border-left: 3px solid #ccc;
    }

    li.active > a {
      color: #000;
      border-left: 3px solid #444;
    }

    a:hover {
      color: #000;
      border-left: 3px solid #444;
      text-decoration: none;
    }

    span.current {
      color: #000;
      border-left: 3px solid #444;
    }

    ul {
      a, span.current {
        padding: 3px 5px 3px 30px;
      }
    }
  }
}


@media (max-width: 1400px)  {
  #menu {
    width: 165px;
    width: 15vw;
    margin-right: 0px;
  }
}

@media (max-width: 1200px)  {
  #menu {
    width: 150px;
    width: 15vw;
    font-size: 12px;
    margin-right: 0px;
  }
}

@media (max-width: 992px)  {
  #menu {
    width: 140px;
    width: 15vw;
    font-size: 12px;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  #menu {
    width: 200px;
    font-size: 14px;
    text-align: left;
    margin: 50px 0px 0px 50px;
    float: left;
  }
}
