
@font-face {
  font-family: "BankGothic";
  src: url('/assets/fonts/bankgthd.eot');
  src: url('/assets/fonts/bankgthd.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/bankgthd.woff') format('woff'),
       url('/assets/fonts/bankgthd.ttf') format('truetype'),
       url('/assets/fonts/bankgthd.svg#bankgothic_md_btmedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/roboto-regular.eot');
  src: url('/assets/fonts/roboto-regular.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/roboto-regular.woff') format('woff'),
       url('/assets/fonts/roboto-regular.ttf') format('truetype'),
       url('/assets/fonts/roboto-regular.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/roboto-light.eot');
  src: url('/assets/fonts/roboto-light.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/roboto-light.woff') format('woff'),
       url('/assets/fonts/roboto-light.ttf') format('truetype'),
       url('/assets/fonts/roboto-light.svg#robotolight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "BrushScript";
  src: url('/assets/fonts/BrushScriptStd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
