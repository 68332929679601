/*
 * Main content
 */

#content {
  padding: 20px;

  .page-header {
    margin-top: 0;
  }

  span.ratna {
    font-family: "BankGothic", Helvetica, Arial;
    font-size: 40px;
    color: #53241A;
    font-weight: normal;
  }

  h1 small {
    font-family: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 22px;
    line-height: 1.4;
  }

  @media (max-width: 992px) {
    h1 small {
      display: block;
      margin: 8px 0px 12px 0px;
    }
  }

  @media (min-width: 992px) {
    h1 small {
      display: inline;
      margin: 0 0 0 30px;
    }
  }

  .teaser {
    font-family: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 200;
    line-height: 1.4;
  }

  h1 {
    font-family: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 30px;
    margin: 30px 0 15px 0;
    text-align: center;
    color: #000;
  }

  h2 {
    font-family: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 24px;
    margin: 30px 0 15px 0;
    text-align: center;
    color: #000;
  }

  h3 {
    font-family: "Roboto", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 200;
    font-size: 20px;
    color: #000;
    margin: 15px 0 10px 0;
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
    border: 0px none;
  }

  .caption h4.brush {
    font-family: "BrushScript", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 30px;
  }

  table {
    margin: 10px auto 20px auto;
  }

  table {
    font-size: 17px;
    line-height: 24px;

    td {
      padding: 0 10px;
      color: #000;
    }
  }
}

#body {
  p {
    text-align: center;
    margin: 20px 0 20px 0;
  }
}
